.resolution-button {
    bottom: 861px;
    margin: 0 10px;
    background-color: #FAF9F6;
    color: #000;
    width: 53px;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
  
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s; /* For a smooth transition */
  }

  .resolution-button:hover {
    background-color: #f2f2f2;
  }

  .dark-mode .resolution-button {
    background-color: black;
    color: white;
  }


  .dark-mode .resolution-button:hover,
.dark-mode .resolution-button.active {
  background-color: #333333;
  color: #ffffff;
}



  .resolution-options {
    position: absolute;
    top: 100%; /* Aligns the top of the dropdown right below the button */
    left: 9.5px; /* Aligns the left edge of the dropdown with the left edge of the container */
    z-index: 1000; /* High enough to overlay other content */
    background-color: #FFF; /* Assuming you want a white background */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds some shadow for better visibility */
    border-radius: 4px; /* Optional: Matches the button's border radius */
    width: max-content; /* Ensure the width is as wide as the content, adjust as necessary */
  }
  .dark-mode .resolution-options {
    background-color: black;
    color: white;
  }

  .resolution-select-container {
    position: relative;
    display: inline-block; /* Allows the dropdown to align right below the button */
  }

  .option {
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.2s ease; /* Smooth transition for hover effect */
  }
  
  /* Hover effect for options */
  .option:hover {
    background-color: #F0F3FA;
  }
  
  /* Style for the selected option */
  .option-selected {

    background-color: #8E55FA; /* Blue background for selected option */
    color: white; /* White text for selected option */
  }
  
  /* Ensure selected option style takes precedence over hover */
  .option-selected:hover {
    background-color: #8E55FA;
    color: white;
  }
  .dark-mode .option:hover {
    background-color: #333333;
    color: #ffffff;
  }
  
  /* Dark mode styles for selected option */
  .dark-mode .option-selected {
    background-color: #2A2E39;
    color: #8E55FA;
  }
  
  /* Ensure selected option style takes precedence over hover in dark mode */
  .dark-mode .option-selected:hover {
    background-color: #2A2E39;
    color: #8E55FA;
  }
  