.search-bar {
  position: absolute;
  width: 300px; /* Set the width of the search bar */
  z-index: 10; /* Ensure it's above other elements */
  align-items: center;
  justify-content: center;
}
.search-bar input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-right: 5px;
  box-sizing: border-box; /* Include padding and border in the element's total width */
}
.search-bar-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the form within the container */
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 5px;
  width: 100%; /* Make the container fill its parent's width */
  max-width: 600px; /* Maximum width of the container */
  min-width: 600px;
  /*box-shadow: 0 2px 4px rgba(0,0,0,0.1)*/
  margin: auto; /* Center the container */
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for background and border color */
}

.search-bar-container:hover {
  background-color: #F0F3FA; /* Change background color on hover */
  border: 1px solid  #936BC3; /* Change border color on hover */
}
.search-form {
  display: flex;
  width: 100%; /* Use the full width of its container */
  align-items: center;
  margin-right:18%;
}
.search-icon {
  position: absolute;
  left: 9px;
  height: 20px;
  color: #333;
  bottom: 5.2px;
  width: 20px;
}
.search-icon-button {
  background: none;
  border: none;
  cursor: text;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clickable-text {
  font-size: 15px;
  display: flex;
  align-items: center; /* Ensure text and separators are aligned on the same line */
  cursor: text;
  white-space: nowrap; /* Prevent the text from wrapping */
}

.search-separator {
  height: 17px;
  width: 2px;
  background-color: #c3c0c0;
  margin: 0 7px;
  border-radius: 7px;
}
.search-data {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Adjust spacing as needed */
  white-space: nowrap; /* Keep data together on one line */
}

.search-input {
  font-weight:550;
  font-size:15px;
  flex-grow: 1;
  border: none;
  outline: none;
  background-color: transparent;
  width: 5%; /* Adapt width to parent while considering padding and search icon */
  box-sizing: border-box;
}
.search-input::placeholder {
  font-weight: normal; /* Make the placeholder text not bold */
}

/* No changes needed for .search-button, but ensure it doesn't expand beyond the container's width */
.search-button {
  /* Existing styles */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Keep content from spilling outside */
  text-overflow: ellipsis; /* Show ellipsis for overflow text */
}
.control-icons {
  display: flex;
  align-items: center;
  color: #333; /* Adjust color as needed */
  font-size: 1.2em; /* Adjust size as needed */
}
.search-symbol {
  font-family: monospace;
}