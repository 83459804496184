.indicator-settings-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 14vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Ensure it's above other overlay components */
  }
  
  .indicator-settings-panel {
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
    width:100%;
  }
  
  .edit-indicator-title {
    padding:7px;
    font-weight: bold;
    background-color: black;
    color: white;
    border: 1px solid black; /* Changed to blue border as shown in your picture */
    text-align: center; /* Center the title text */
    width:100%;
    font-size: 16px
  }
  .parameters-title {
    margin-top: 20px; /* Increase top margin for more space below the title */
    text-align: left;
    margin-bottom: 20px; /* Adjust based on your preference */
    font-weight: bold;
    margin-left:15px;
    font-size: 15px;
  }
  
  .ma-options {
    display: flex;
    flex-direction: column;
    margin-top: 20px; /* Additional spacing from the title */
  }
  .apply-button {
    background-color: black; /* Or any color you prefer */
    color: white; /* Or any color you prefer */
    border: 1px solid black; /* Or `none` if you don't want borders */
    padding: 8px 16px; /* Adjust the padding as needed */
    cursor: pointer;
    border-radius: 10px;
    font-size:14px;
  }
  
  

  .indicator-box-apply-buttons {
    position: absolute;
    bottom: 12%;
    padding: 10px;
    display: flex;
    width:100%;
    border-top: 1px #ccc solid;
  }
  
  .indicator-apclose-buttons {
    margin-left: 83px;
    margin-top: 1vh;
  }
  .apply-button-container {
    width: 100%; /* Take full width */

  }
  .apply-button:active {
    background-color: #0056b3; /* Dark blue background on click */
  }
  .ma-type-container {

    margin-bottom: 20px; /* Space between the MA Type and MA Period containers */
  }


  .ma-period-container {
    
    margin-bottom: 20px; /* Space between the MA Type and MA Period containers */
  }
  
  
  .ma-type-container select,
  .ma-period-container input {
    padding: 10px;
    margin-top: 5px; /* Space between label and input/select */
  }
  
  .ma-options.disabled {
    opacity: 0.5;
  }
  
  .close-button {
    background-color: white;
    color: black;
    border: 1px black solid;
    border-radius: 10px;
    padding: 8px 16px; /* Adjust the padding as needed */
    cursor: pointer;
    margin-right: 10px; /* Space between buttons */
    font-size:14px;
  }

/* Style for the dropdown container */
.dropdown-container {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-container{
  font-size: 14px;
  flex-grow: 1;
  margin-right: 25px;
}
.dropdown-container:hover{
  background-color: #F0F3FA;
}
.dark-mode .dropdown-container:hover{
  background-color: #353946;
}
.input-container {
  font-size: 14px;
}
.input-container:hover {
  background-color: #F0F3FA;
}
.dark-mode .input-container:hover {
  background-color: #353946;
}
.input-container input:hover {
  background-color: #F0F3FA;
}
.dark-mode .input-container input:hover {
  background-color: #353946;
}
.dropdown-container:hover, .input-container:hover {
  background-color: #F0F3FA;
}

/* Dark mode hover styles */
.dark-mode .dropdown-container:hover, .dark-mode .input-container:hover {
  background-color: #353946;
  
}

.light-mode-hover:hover {
  background-color: #F0F3FA;
}
.input.light-mode {
  background-color: #FFFFFF;
}

.input.dark-mode {
  background-color: #000000;
}

/* Hover background colors based on mode */
.input.light-mode:hover {
  background-color: #F0F3FA; 
}

.input.dark-mode:hover {
  background-color: #353946;  
}
/* Dark mode hover */
.dark-mode-hover:hover {
  background-color: #353946;
}
.indicator-settings-panel .dropdown-container:hover,
.indicator-settings-panel .input-container:hover {
  background-color: #F0F3FA; /* Light mode hover background color */
}
.input-container input.light-mode-hover:hover {
  background-color: #F0F3FA;
}

/* Dark mode hover for input inside container */
.input-container input.dark-mode-hover:hover {
  background-color: #353946;
}
.indicator-settings-panel.dark-mode .dropdown-container:hover,
.indicator-settings-panel.dark-mode .input-container:hover {
  background-color: #353946; /* Dark mode hover background color */
} 
.indicator-settings-panel .input-container.dark-mode-hover:hover,
.indicator-settings-panel .input-container.light-mode-hover:hover {
  background-color: #F0F3FA; /* for light mode */
}

.indicator-settings-panel.dark-mode .input-container.dark-mode-hover:hover {
  background-color: #353946; /* for dark mode */
}
.dropdown-container select {
  border: none;
  outline: none;
  padding: 10px;
  width: calc(100% + 2px); /* Adjust for border */
  background: transparent;
  position: relative;
  z-index: 2;
}

/* Style for the input container */
.input-container {
  width: 47%;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow:hidden;
}

.input-container input {
  border: none;
  outline: none;
  padding: 10px;
  width: calc(100%); /* Adjust for padding */
}
.ma-type-container select,
.ma-period-container input {
  margin-top: 0;
}
.ma-type-container,
.ma-period-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  margin-bottom: 20px; /* Space between the containers */
}

.ma-type-container label {
  margin-right: 49px; /* Space between label and select/input */
  white-space: nowrap; /* Prevents the text from wrapping */
  margin-left:15px;
  font-size:14px;
}

.ma-period-container label {
  margin-right: 62px; /* Space between label and select/input */
  white-space: nowrap; /* Prevents the text from wrapping */
  margin-left:15px;
  font-size:14px;
}

.apply-button:hover {
  background-color: #333333; /* lighter grey for light mode hover */
}

/* Dark mode styles */
.dark-mode .apply-button {
  background-color: #454c5e; /* darker grey for dark mode */
}

/* Dark mode hover */
.dark-mode .apply-button:hover {
  background-color: #353946; /* even darker grey for dark mode hover */
}
/* Dark mode hover */
.close-button:hover {
  background-color: black; /* even darker grey for dark mode hover */
  color: white
}
/* Dark mode hover */
.dark-mode .close-button {
  background-color: black; /* even darker grey for dark mode hover */
  border: 1px white solid;
  color: white
}
/* Dark mode hover */
.dark-mode .close-button:hover {
  background-color: #454c5e; /* even darker grey for dark mode hover */
  border: 1px #454c5e solid;
  color: white
}