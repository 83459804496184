
.stock-info-main {
  padding:10px;
}


.stock-info-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.stock-title-sticky {
  position: sticky;
  display: flex;
  top: 0; /* Adjust as needed */
  background: black; /* Same as the background color of the container */
  z-index: 3; /* Ensure it's above the other content */
  font-size: 16px;
  font-weight: bold;
  color: white;
  width: 100%; /* Sets the width to take up the full width of its parent container */
  padding: 5px; /* Optional, for some internal spacing */
  box-sizing: border-box; /* Ensures padding doesn't add to the total width */
}
.stock-title-box{
  margin-left: 5px;

}
.back-button {
  width: 40px; /* Set width of the button */
  height: 50px; /* Set height of the button */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  cursor: pointer;
}
.data-subheader {
  font-size: 25px;
  font-weight: bold;
  white-space: nowrap; /* Prevents text from wrapping to ensure ellipsis works */

  text-overflow: ellipsis; /* Adds ellipses for any text that overflows */
}

.section-left{
  display: flex;
  flex-direction: column;
  justify-content: start; /* Aligns items vertically *//* Set a maximum width to match, ensuring it does not grow or shrink */
}
.section-main-left{
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Aligns items vertically */
  margin-right: 30px; /* Space between left and right sections */
  margin-top: 5px;

}

.stock-info-row:first-child > .section-right {
  flex: 1;
  transition: transform 0.3s ease;
  margin-top: 1px; /* Adjust as needed to align with the start of the red line */
}
.stock-info-row:nth-child(2) > .section-right {
  flex: 1;
  transition: transform 0.3s ease;
  margin-top: 10px; /* Adjust as needed to align with the start of the red line */

}
.stock-info-row:nth-child(3) > .section-right {
  flex: 1;
  transition: transform 0.3s ease;

}
.stock-info-row:nth-child(2) > .section-left .subtitle-container {
  margin-left: 30px;
  margin-top: 15px;
}
.stock-info-row:nth-child(3) > .section-left .subtitle-container {
  margin-left: 30px;
  margin-top: 15px;
}
.seperator-container {
  margin-right:30px;
}
.stock-info-row:nth-child(2){
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.stock-info-row:nth-child(3){
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.vertical-separator {
  width: 5px;
  height: 193px;
  background-color: #ccc; /* Make sure the color is visible against the background */
  margin: 10px;
  border-radius: 5px; 
  align-self: stretch; /* This will make the separator stretch to the full height of the row */
}
.stock-info-row:first-child > .vertical-separator {
  width: 5px;
  height: 190px;
  background-color: #ccc; /* Make sure the color is visible against the background */
  margin-right: 25px;
  border-radius: 5px; 
  align-self: stretch; /* This will make the separator stretch to the full height of the row */
}
.stock-info-row:nth-child(2) > .seperator-container {
  margin-top:50px;
  margin-right:30px;
  margin-left:30px
}

.stock-info-row:nth-child(3) > .seperator-container {
  margin-top:40px;
  margin-right:30px;
  margin-left:30px
}

.stock-info-row:nth-child(2) > .vertical-separator {
  width: 5px;
  height: 230px;
  background-color: #ccc; /* Make sure the color is visible against the background */
  margin-right: 25px;
  border-radius: 5px; 
  align-self: stretch; /* This will make the separator stretch to the full height of the row */
}
.stock-info-row:nth-child(3) > .vertical-separator {
  width: 5px;
  height: 230px;
  background-color: #ccc; /* Make sure the color is visible against the background */
  margin-right: 25px;
  border-radius: 5px; 
  align-self: stretch; /* This will make the separator stretch to the full height of the row */
}
.stock-title{
  font-size: 30px;
  font-weight: bold;
}
.stock-info-row:nth-child(3) > .section-left {
  position: relative;
    display: flex;
    margin-top: 20px;
    left: 0;
    top: 0;
}
 .meta-sentiment-title {
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap; /* Ensure the title does not wrap */
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Show ellipsis if the text overflows */
  min-width: 46%; /* Allow the title to shrink below its content size */
}

.stock-subtitle{
  font-size: 18px;
  white-space: pre;
}
.data-subheader{
  font-size: 20px;
  font-weight: bold;
}
.metadata-subheader{
  font-size: 18px;
  margin-top: 35px;
}
.stock-price-currency {
  display: flex;
  align-items: baseline; /* Align items on their baseline for better visual alignment */
}
.stock-price{
  font-size: 20px;
  font-weight: bold;
  white-space: pre;
}
.stock-currency {
  font-size: 12px;
  font-weight: bold;
  padding-left: 5px; /* Adjust as needed for spacing */
}
.stock-data {
  font-size: 18px;
  padding-left: 5px; /* Adjust as needed for spacing */
  width:9.1vw
}
.subtitle-container {
  align-self: start;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  margin-top: 30;
  margin-bottom: 8px; /* Space between this and the next subtitle or content */
}
.buzz-change-zero { margin-left: 0.07em; }

.pie-chart-container {
  display: flex;
  align-items: center;
}

.pie-chart {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: conic-gradient(
    var(--fill-color) 0%, 
    var(--fill-color) var(--percentage), 
    var(--darkmode-fill-color) var(--percentage), 
    var(--darkmode-fill-color)100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-weight: bold;
  color: var(--fill-color);
  margin-right: 10px;
  margin-top: 10px;
  --inner-bg-color: white; /* Default inner background color */
  --darkmode-fill-color: #eee;
}


.dark-mode .pie-chart {
  --inner-bg-color: black; /* Dark mode inner background color */
  --darkmode-fill-color: #505050;
}
.pie-chart:before {
  content: '';
  width: 100px; /* 80% of the pie-chart size to create the donut hole */
  height: 100px;
  background: var(--inner-bg-color);
  border-radius: 50%;
  position: absolute;
}
.dark-mode-text {
  color: #FFFFFF;
}
.pie-chart-text {
  position: absolute;
  font-size: 20px;
  z-index: 1;
}

.full-pie-chart-container {
  display: flex;
  align-items: center;
  position: relative;
}

.full-pie-chart {
  width: 140px;
  height: 130px;
  border-radius: 50%;
  background: conic-gradient(
    #eee 0%, 
    #eee var(--percentage), 
    #eee var(--percentage), 
    #eee 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.full-pie-chart-text {

  border-radius: 50%;
  width: 70%; /* Adjust the size to fit your design */
  height: 70%; /* Adjust the size to fit your design */
  display: flex;
  align-items: center;
  justify-content: center;
}
.change {
  display: flex;
  flex-direction: column;
  font-size:18px;
  margin-left:20px;
  margin-top:9px;
}

.attention-buzz-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px; /* Space between title and list */
}
.pie-selector-margin-left {
  margin-left: 30px;
  margin-top:-5px
}
.buzz-details {
  display: flex;
  position: relative;
  align-items: center;
  padding-right: 20px;
  width: 100vh;
  height: 20vh;
  overflow: hidden;
}
.socialMediaMA-margin {
  margin-left: 40px;
}
.buzz-list {
  margin-right: 20px; /* Adjust this value to add more space between the list and the pie chart */
}
.buzz-list li {
  font-size: 12px;
}

.pie-chart-container.small {
  flex-direction: row;
  align-items: center;
}

.pie-chart-container.small .pie-chart {
  width: 30px; /* Smaller pie chart for buzz */
  height: 30px;
  margin-right: 5px;
}

.total-buzz {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.totalbuzz-ml{
  margin-left: 36%;
  font-weight: bold;
}
.news-buzz {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.social-media-buzz {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.total-buzz .change {
  color: #d9534f; /* Example color for negative change */
}

.buzz-item {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 1px; /* Spacing between items */
}


.color-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  margin-left: 3px;
  margin-top: 1px;
}


/* Use these classes for the corresponding circles */
.news-buzz-color {
  background-color: #2CAFFE; /* Color for news buzz */
}

.social-media-buzz-color {
  background-color: black; /* Color for social media buzz */
}


@keyframes fillPieChart {
  from {
    background: conic-gradient(#B100FF 0%, #B100FF 0%, #eee 0%, #eee 100%);
  }
  to {
    background: conic-gradient(#B100FF 0%, #B100FF var(--percentage), #eee var(--percentage), #eee 100%);
  }
}

.pie-chart.animate {
  animation: fillPieChart ease-out forwards; /* Adjust the time and easing here */
}


.negative {
  color: #FF2400; /* Change to a darker red if needed */
}

.positive {
  color: #00A36C; /* Change to a darker green if needed */
}
.seperator-container:first-child > .vertical-separator {
  width: 5px;
  height: 193px;
  background-color: #ccc;
  margin: 10px;
  border-radius: 5px;
}
.seperator-container:nth-child(2) > .vertical-separator {
  width: 5px;
  height: 184px;
  background-color: #ccc;
  margin: 10px;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 8px;
  border-radius: 5px;
}
.css-1qq679y {
  z-index: 1 !important;
}
.sentiment-button-container {
  white-space: nowrap; /* Prevents wrapping */
  overflow: hidden; /* Keeps everything in a single line, hiding overflow */
  text-overflow: ellipsis; /* Adds an ellipsis for text that doesn't fit */
  min-width: 160px; /* Adjust based on your layout's needs */
  border-radius: 4px;
  margin-top: 4px;
}

.sentiment-button {
  min-width: 160px; /* Ensure button does not shrink too small */
}
