.crosshair-button {
    bottom: 861px;
    margin: 0 10px;
    background-color: #FAF9F6;
    color: #000;
    width: 30px;
    height: 30px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s; /* For a smooth transition */
  }
  
  .crosshair-button.active {
    background-color: black;
    color: white;
  }
  
  .crosshair-button:hover {
    background-color: #f2f2f2;
  }

/* New: Styles for hovering over an active button */
.crosshair-button.active:hover {
  background-color: #f2f2f2; /* Hover background for active button */
  color: black; /* Hover text color for active button */
}
button.crosshair-button.dark-mode {
  background-color: black;
  color: white;
}

button.crosshair-button.dark-mode.active {
  background-color: #2A2E39;
  color: #8E55FA;
}

button.crosshair-button.dark-mode:hover {
  background-color: #333333;
  color: #ffffff;
}
