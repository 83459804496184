.news-container {

  height: 100%; /* Adjust as needed to fill container */
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
}

.news-container h1 {
  font-size: 16px;
  padding: 5px;
  
  background-color: black;
  color: white;
  font-weight: bold;
}

.news-items-container {
  border-top: 1px solid grey;
}

.news-header-container {
  background-color: black;
}
.news-item {
  padding: 10px 0;
  padding-left: 20px;
  font-weight: 700;
  border-bottom: 1px solid lightgrey;
  transition: background-color 0.3s;
}

.news-item:hover {
  background-color: #F0F3FA; /* Light grey background on hover */
}
.news-header {
  display: flex;
  justify-content: start;
  font-size: 14px;
}

.news-date {
  color: #8E55FA;
  margin-right: 10px;
}

.news-publisher {

  color: #878787;  /* additional styles if needed */
}

.news-container::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for Webkit browsers */
}

.news-container:hover::-webkit-scrollbar {
  width: 8px; /* Show scrollbar when hovered */
}

.news-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}
.news-title {
  font-size: 18px;
  margin-top: 5px;
}
.news-title a {
  color: inherit; /* Use the same color as the non-link text by default */
  text-decoration: none; /* No underline */
}

.news-title a:hover {
  text-decoration: underline; /* Underline on hover for better user experience */
}