@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
* {
  user-select: none;
  font-family: 'Lato', sans-serif; /* Apply Noto Sans globally */
}
  .watchlist-header{
    display: grid;
    grid-template-columns: minmax(0, 0.4fr) repeat(7, minmax(0, 0.4fr));
    text-align: center;
    position: relative; /* Add this for positioning the pseudo-element */
}
.stock-item {
  display: grid;
  grid-template-columns: minmax(0, 0.4fr) repeat(7, minmax(0, 0.4fr));
  text-align: center;
  position: relative; /* Add this for positioning the pseudo-element */
  padding: 8px;
  
}

.stock-item {
  display: grid;
  grid-template-columns: minmax(0, 0.4fr) repeat(7, minmax(0, 0.4fr));
  text-align: center;
  position: relative; /* Add this for positioning the pseudo-element */
  padding: 8px;
  flex-shrink: 0; /* Prevent items from shrinking */
}
.stock-item:hover {
  background-color: var(--hover-background-color);
  cursor: pointer;
}
.header-item-with-line::after, .stock-column-with-line::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #D3D3D3;
}
.watchlist-header {
  display: grid;
  grid-template-columns: minmax(0, 0.4fr) repeat(7, minmax(0, 0.4fr));
  text-align: center;
  position: sticky; /* Add this for positioning the pseudo-element */
  z-index: 10;
  padding-top: 4px;
  padding-left: 4px;
  background-color: white;
}
.header-item{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  font-weight: bold; /* Only for header items, remove for stock-column if not needed */
  font-size: 15px;
  position: relative; /* Added for positioning the pseudo-element */
  color: #767575;
}
.stock-column {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px; /* Only for header items, remove for stock-column if not needed */
  position: relative; /* Added for positioning the pseudo-element */
  font-size: 14px;
}
.stock-symbol-column{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 10px;
  position: relative;
  font-size: 14px;
  font-weight:550;
}

.stock-list .stock-item:hover {
  background-color: #F0F3FA; /* Light grey background on hover */
  cursor: pointer; /* Change cursor to indicate clickable */
}


  .stock-list-inner-container {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
  
  .stock-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

      flex-direction: column; /* Stack items vertically */
  }
  
  
  .stock-item.selected {
    border: 0px solid #B100FF; /* Purple background for selected item */
    color: #000000; /* Black text color for selected */
  }
  
  
  .main-header {
    position:sticky;
    top: 0;
    background-color: white; /* Purple background */
    color: black; /* Change text color to white */
    padding: 10px;
    text-align: left;
    font-weight: bold; /* Make main header text bold */
    font-size: 16px;
    z-index: 10;
  }

  /* For vertical separators, assuming .watchlist-header and .stock-list are the parent containers */
.watchlist-header{
  position: relative;
}

.stock-list {
  position: relative;
  background-color: white;
  z-index: 0;
}



.stock-list-separator {
  bottom: 910px; /* or adjust as needed */
  left: 956px;
  right: 0;
  height: 3px;
  background-color: #D3D3D3; /* The color of the separator */
 
}

.stock-list-container {
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-y: auto; /* This will enable scrolling */
  height: calc(100vh);
}
.stock-list-headers {
  position: sticky;
  top: 0;
  z-index: 10; /* Ensure it's above the scrollable list */
  background-color: white; /* To not see the list scroll underneath */
}
.stock-list-details-container::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for Chrome, Safari and Opera */
}

.stock-list-details-container:hover::-webkit-scrollbar {
  width: 8px; /* Show scrollbar when hovered */
}

.stock-list-details-container::-webkit-scrollbar-thumb {
  background: #888; 
}

.stock-list-details-container:hover::-webkit-scrollbar-thumb {
  background: #555; 
}
.stock-list-sep{
  content: '';
  position: absolute;
  right: 0; /* Position the line at the right edge of the column */
  top: 8px; /* Align to the top of the column */
  bottom: 0; /* Align to the bottom of the column */
  width: 2px; /* Width of the separator line */
  background-color: #D3D3D3; /* Color of the separator line */
  height:209px;
  z-index: 0;
}


.header-item-with-line::after {
  content: '';
  left: 90%; /* Position the separator at the right edge of the column */
  top: 0.5vh; /* Align to the top of the column */
  bottom: 0; /* Align to the bottom of the column */
  width: 2px; /* Width of the separator line */
  background-color: #D3D3D3; /* Color of the separator line */
  z-index: 0; /* Ensure it's behind the content */
  height: 15.5em;
}
.dark-mode .header-item-with-line::after {
  background-color: #2A2E39; /* Dark mode color of the separator line */
}
/* For vertical separators in the stock items */
.stock-column-with-line::after {
  content: '';
  position: sticky; /* Keep the separator fixed within its container */
  left: 100%; /* Position the separator at the right edge of the column */
  top: 0; /* Align to the top of the column */
  bottom: 0; /* Align to the bottom of the column */
  width: 2px; /* Width of the separator line */
  background-color: #D3D3D3; /* Color of the separator line */
  z-index: 0; /* Ensure it's behind the content */
}


.stock-list-container-background {
  flex-grow: 1;
  background-color: white;
  height:100%;
  z-index: 20;
}