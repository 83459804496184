.user-settings-panel {
    position: absolute;
    top: 100%; /* Bottom of the settings button */
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 15px;

    padding: 10px 10px 4px 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 200; /* Ensure it's above other content */
  }
  
  .view-mode-row {
    display: flex;
    align-items: center;

  }

  .version-number-container {
    color: black;
    font-size: 15px;
    margin-top:10px;
  }
  .user-option-separator {
    margin-top: 10px;
    border-bottom: 1px solid lightgrey;
  }
  .sign-out-text {
    color: black;
    font-size: 15px;
    margin-top:10px;
  }
  .sign-out-row {
    cursor: pointer; /* Changes the cursor to a pointer to indicate clickable */
    padding-bottom: 6px;
    margin-top:4px;
    align-items: center; /* Centers the icon and text vertically */
  }
  .sign-out-row:hover {
    background-color: #F0F3FA; /* Light mode hover color */
}

  /* Dark mode hover effect */
  .dark-mode .sign-out-row:hover {
      background-color: #333333; /* Dark mode hover color */
  }
  .view-mode-button {
    Display: flex;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    cursor: pointer;
    height: 19px;
    align-items: center;
  }
  .dark-mode-text {
    font-size: 15px;
    color: #000;
    margin-left: 10px;
    width: 50%;
    margin-right: 85px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 80px; /* Width of the switch */
    height: 22px; /* Height of the switch */

  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 22px; /* Added to make slider rounded */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Adjusted for the new switch height */
    width: 18px; /* Adjusted for a proper circular knob */
    left: 2px; /* Adjusted to align knob inside the switch */
    bottom: 2px; /* Centered vertically */
    background-color: white;
    transition: .4s;
    border-radius: 50%; /* Makes the knob circular */
  }
  
  input:checked + .slider {
    background-color: black;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    transform: translateX(30px); /* Adjusted to move the knob to the end */
  }