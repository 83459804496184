.condition-builder-container {

  height: 100%; /* Adjust as needed to fill container */
  overflow-y: auto; /* Enables vertical scrolling if content overflows */
  display: flex;
  flex-direction: column;
}

.condition-builder-container h1 {
  font-size: 16px;
  padding: 5px;
  
  background-color: black;
  color: white;
  font-weight: bold;
}


.condition-builder-header-container {
  background-color: black;
  height:12%
}
.condition-builder-main {
  display: flex;
  flex-direction: column;
}

.coming-soon-container h2 {
  margin-top: 10vh;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: auto; /* Ensures it stays centered */
  color: #8E55FA;

}