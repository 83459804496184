.Home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligns children to the start of the main axis */
  height: 100vh;
  margin: 0;
  padding: 0;
  background: white;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}
.indicator-and-search-container {
  display: flex; /* Aligns children inline (horizontally) */
  justify-content: left; /* Centers button horizontally in the container */
  align-items: center;
  padding: 5px; /* Space around the button */
  border: 2px solid #D3D3D3; /* White border as requested */
  width: 100%; /* Full width to extend across the screen */
  box-sizing: border-box; /* Ensures padding is included in width */
}
.search-container {
  border-radius: 5px;
    position: absolute;
    right: 34.5vw;
}

.right-sidebar {
  position: absolute; /* Position it over the main content */
  right: 0; /* Align to the right edge of the screen */
  width: 42px; /* Width of the sidebar */
  height: 100vh; /* Full height of the viewport */
  background-color: white; /* Sidebar background color */
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  justify-content: flex-start; /* Align buttons to the top */
  box-sizing: border-box; /* Include padding in the height **/
  border-left: 1px solid #ccc; /* Border color */
  z-index: 100;
}
.right-sidebar.dark-mode {
  background-color: black; /* Ensure the entire sidebar has a black background in dark mode */
  border-left: 1px solid #2A2E39;
}
.sidebar-button {
      /* width: auto; */
      padding: 7px;
      /* margin: 10px auto; */
      margin-bottom: 10px;
      margin-top: 10px;
      background-color: transparent;
      color: #000000;
      border: 1px solid transparent;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      box-sizing: border-box;
}
.settings-logo-container {
  display: flex; /* Enables Flexbox */
  align-items: center; /* Aligns children vertically in the middle */
  width: 100%;
  justify-content: space-between; /* Adjusts the spacing between the children */
    position: relative; 
}
.sidebar-button.active {
  color: white; /* Icon color when active */
  background-color: black; /* Background color when active */
}

.sidebar-button:hover:after {
  position: absolute;
  /* content: attr(title); */
  display: inline-block;
  left: 0px;
  padding: 5px;
  background-color: rgb(241, 28, 28);
  color: white;
}
.settings-button-container{
    position: absolute;
    right: 0.5%;
    height:25px;
}
.right-sidebar.dark-mode .sidebar-button {
  color: white;
  background-color: black;
}

.right-sidebar.dark-mode .sidebar-button:hover {
  color: #ffffff;
  background-color: #333333;
}
.right-sidebar .sidebar-button:hover {
  background-color: #F0F3FA;
}
.right-sidebar .sidebar-button.active:hover {
  color: white; /* Icon color when active */
  background-color: black; /* Background color when active */
}
.right-sidebar.dark-mode .sidebar-button.active {
  color: #8E55FA;
  background-color: #2A2E39;
}
.content-container {
  display: block;
  position: relative;
  flex: 1;
  width: 100%;
  max-height: 100vh; /* Ensure it doesn't exceed viewport height */
}

.stock-data-container {
  flex-grow: 1;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: stretch;
  height: 100%; /* Full height of parent */
  overflow: hidden; /* Hide overflow */
}

.info-wrapper {
  right: 0;
  background: white;
}

.stock-list-info-container {
  flex-basis: 50%; /* Adjust the width ratio as needed */
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-left: 4px solid #D3D3D3;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.stock-item {
  cursor: pointer;
  padding: 5px;

  border-bottom: 1px solid #e2e2e2; /* Purple border for items */
}

.stock-item.selected {
  border: 2.5px solid #936BC3;/* Purple background for selected item */
  color: #000000; /* Black text color when selected */
}

.separator {
  bottom: 55%; /* or adjust as needed */
  left: 39%;
  right: 0;
  height: 5px;
  background-color: #D3D3D3; /* The color of the separator */
  cursor: row-resize;
  position: revert;
  z-index: 10
}

.logo-container img {
  width: auto;
  /* max-width: 200px; */
  /* max-height: 200px; */
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2px;
  margin-top: 2px;
}
.stock-list-outer-container {
  display: flex;
  flex-direction: column;
  width: 48%;
  height: 100vh; /* Adjust the height to prevent app-level scrolling */
  border-left: 5px solid #D3D3D3;
  position: absolute;
  right: 2.2%;
  
}
.stock-list-inner-container {
  width: 100%; /* Or whatever width you desire for the actual stock list */
  overflow-y: auto; /* Allows for scrolling within the stock list */
  padding: 2px; /* Padding inside the container */
  color: black; /* White text color for the stock list */
}

.vertical-divider {
  position: absolute;
  color: red;
  height: 100%;
  width: 5px;
  margin-left: -7px;
  cursor: col-resize;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; /* Adjust as needed */
}

.adjust-width {
  width: 75vh !important; /* Override other width settings */
}
.stock-list-details-container {
  transition: transform 0.3s ease-out; /* Smooth transition for sliding effect */
}

.stock-list-container {
    position: relative; /* Maintain position within its parent */
  width: 100%; /* Limit width to prevent overflow */
  overflow-x: hidden; /* Hide horizontal overflow */
  overflow-y: auto; /* Allow vertical scrolling */
  height: auto; /* Adjust height based on content */
  z-index: 10;
}

.stock-list-container:hover{
  overflow-y: auto; /* Enable scrolling within these containers */
}
.stock-list-container::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for Webkit browsers */
}

.stock-list-container:hover::-webkit-scrollbar {
  width: 8px; /* Show scrollbar when hovered */
}

.stock-list-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}
.stock-info-container {
  flex: 1; /* Each container takes equal space */
  overflow-y: hidden; /* Enable scrolling within these containers */
  overflow-x: hidden; /* Hide vertical scrollbar by default */
  height: 59.5vh;
}
.stock-info-container:hover{
  overflow-y: auto; /* Enable scrolling within these containers */
  overflow-x: auto; /* Enable horizontal scrolling on hover */
}
.stock-info-container::-webkit-scrollbar {
  width: 0px; /* Hide scrollbar for Webkit browsers */
  height: 0px; /* Hide horizontal scrollbar for Webkit browsers */
}

.stock-info-container:hover::-webkit-scrollbar {
  width: 8px; /* Show scrollbar when hovered */
  
  height: 8px; /* Show horizontal scrollbar when hovered */
}

.stock-info-container::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 4px;
}
.content-container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height of the viewport */
  overflow: hidden; /* Hide overflow */
}

/* Light mode variables (default) */

