      .indicator-panel {
        position: fixed;
        top: 11vh;
        left: 0;
        height: 100%;
        transform: translateX(-100%); /* Start off-screen to the left */
        transition: transform 0.3s ease-out; /* Smooth transition for sliding effect */
        display: flex;
        flex-direction: column; /* Stack options vertically */
        justify-content: start;
        background: white;
        z-index: 100;
      }

      .indicator-panel.open {
        transform: translateX(0); /* Slide into view */
      }

      .panel-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background: black;
      }

      .studies-title {
        font-size: 16px;
        font-weight: bold;
        padding: 8px;
        flex-grow: 1; /* Title takes up the rest of the space */
      }
      
      .back-button {
        margin-top: 4px;
        margin-right: 8px;
        width: 20px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        border: none;
        cursor: pointer;
      }
      .panel-container {
        background: white;
      ;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
        height: 100%;
        width: auto; /* Auto width to fit content */
        min-width: 200px; /* Minimum width */
      }

    
      .overlay-option:last-child {
      border-top: 1px solid #ccc;
    }


      .panel-column {
        display: flex;
        flex-direction: column;
        min-width: 200px;
        padding: 20px;
      }


      .panel-option {
        padding: 10px;
        justify-content: space-between;
        cursor: pointer;
        font-weight: 550;
        font-size: 15px
      }
      /* Dark mode hover effect for panel options */
      .dark-mode .panel-option:hover {
        background-color: #333333; /* Dark mode hover background */
      }
      .panel-option:hover {
        background-color: #F0F3FA;
      }
      .panel-option.active {
        background-color: #eee; /* Highlight for active option */
      }
      .panel-container.dark-mode .panel-option:hover {
        background-color: #333333; /* Dark mode hover background */
      }
      .panel-option:hover .dots-button {
        display: inline; /* Show dots on hover */
      }

      /* Additional styles for tabs if necessary */
      .tabs {
        display: flex;
        justify-content: space-around;
        background: #f0f0f0;
        padding: 10px 0;
      }

      .tab {
        padding: 10px 20px;
        cursor: pointer;
      }

      .tab.active {
        background-color: #e9e9e9;
        border-bottom: 2px solid #333;
      }

      .tab-content {
        padding: 20px;
        background: #D3D3D3;
      }


      .dots-button {

        float: right;
        cursor: pointer;
        margin-left: 20px;
        margin-top:5px;
        
      }

      .indicator-options-overlay {
        position: absolute;
        z-index: 1000; /* High z-index to ensure it's on top of other elements */
        background-color: white;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        padding: 10px;
        padding-bottom: 4px;
        width:72%;
        display: flex;
        flex-direction: column;
      }

      .overlay-option {
        padding: 9px;
        cursor: pointer;
        font-size:14px
      }

      .overlay-option:last-child {
        border-bottom: none;
      }

      .overlay-option:hover {
        background-color: #F0F3FA;
      }
      .dark-mode .overlay-option:hover {
        background-color: #353946 ; /* Dark mode hover background */
      }