.slidingVertical {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  height: 1.2em;
  width: 5.6em;
  transform: translateY(-7%);
}

.slidingVertical span {
  animation: topToBottom 20s linear infinite 0s;
  -webkit-animation: topToBottom 20s linear infinite 0s;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  font-family: "Noto sans";
  font-weight: 900;
}

.slidingVertical span:nth-child(1) { animation-delay: 0s; }
.slidingVertical span:nth-child(2) { animation-delay: 2.5s; }
.slidingVertical span:nth-child(3) { animation-delay: 5s; }
.slidingVertical span:nth-child(4) { animation-delay: 7.5s; }
.slidingVertical span:nth-child(5) { animation-delay: 10s; }
.slidingVertical span:nth-child(6) { animation-delay: 12.5s; }
.slidingVertical span:nth-child(7) { animation-delay: 15s; }
.slidingVertical span:nth-child(8) { animation-delay: 17.5s; }
@keyframes topToBottom {
  0%, 100% { opacity: 0; }
  /* Adjusted timings for synchronized entry and exit */
  0% { transform: translateY(-100%); opacity: 0; }
  5% { transform: translateY(-100%); opacity: 0; } /* Incoming word starts moving in */
  10% { transform: translateY(0); opacity: 1; } /* Incoming word is fully visible */
  15% { transform: translateY(0); opacity: 1; } /* Stay visible */
  20% { transform: translateY(100%); opacity: 0; } /* Start moving out */
  25% { transform: translateY(200%); opacity: 0; } /* Fully moved out */
}